<template>
  <div style="margin:50px auto;display:block;">

              <v-layout row justify-center>
                  <v-flex xs12 md6>
                      <v-card flat><v-card-text>
                          <p class="title">
                  {{readProductoOnly.pr_nombre}}
              </p>
              <span>Código: {{readProductoOnly.pr_codigo}}</span>
              <v-carousel hide-delimiters>
                <v-carousel-item
                v-for="i in 9"
                :key="i" 
                :src="readProductoOnly.img1"
                ></v-carousel-item>
            </v-carousel>
            <p class="pt-3" style="font-size:25px;color:#00174a;font-weight:900">
                    ${{readProductoOnly.pr_precio}}
                  </p>
          </v-card-text>
      </v-card>
      <v-card flat style="border:solid 1px #ccc">
          <v-card-text class="title">
              Datos del Inmueble
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
              <p style="font-size:16px;color:#333;font-weight:700">
                  Inmueble en: {{readProductoOnly.pr_tipo}}
              </p>
              <p style="font-size:16px;color:#333;font-weight:700">
                  Categoria: {{readProductoOnly.pr_categoria}}
              </p>
              <p style="font-size:16px;color:#333;font-weight:700">
                  Precio Ref: {{readProductoOnly.pr_precio}}
              </p>
              <p style="font-size:16px;color:#333;font-weight:700">
                  Precio BsS: {{readProductoOnly.pr_precio * 3200000}}
              </p>

              <p style="font-size:16px;color:#333;font-weight:700">
                  Construcción: {{readProductoOnly.pr_area}}
              </p>
              <v-img width="150" src="https://inmoglobalve.com.ve/img/qr_img.png"></v-img>
          </v-card-text>
      </v-card>
                  </v-flex>
                  <v-flex xs12 md6></v-flex>
              </v-layout>

              
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            id: this.$route.params.id,readProductoOnly:[]
        }
    },
    methods:{
        async loadSingleProductos(e){
            await axios.post('https://inmoglobalve.com.ve/kkehdhidfd.php',{pr_uid:e}).then(res=>{
                this.readProductoOnly = res.data[0]
            })
        }
    },
    created(){
        this.loadSingleProductos(this.id)
    }
}
</script>

<style>

</style>